.container {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  justify-content: space-around;
}

.title {
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}

.union-button {
  align-content: center;
  font-size: 12px;
  text-decoration: underline;
}

.union-button:hover {
  color: blue;
}
