.catalog-container {
  width: 250px;
  margin-top: 3px;
  margin-right: 15px;
  border: 1px solid var(--border-color);
  background-color: var(--bg-gray);
}

.menu {
  position: relative;
  margin: 3px;
  font-size: 14px;
}

.group {
  padding: 5px;
  background-color: var(--bg-gray);
  /* background-color: var(--secondary-color); */
}

.group:not(:last-child) {
  border-bottom: 1px solid var(--border-color);
}

.group:hover {
  background-color: var(--hover-link-color);
}

.group:hover .subgroup,
.group:hover .subgroup-bottom {
  display: flex;
  flex-direction: column;
}

.subgroup,
.subgroup-bottom {
  position: absolute;
  top: -4px;
  left: 100%;
  display: none;
  width: 480px;
  background-color: var(--bg-gray);
  /* background: var(--secondary-color); */
  border: 1px solid var(--border-color);
  padding: 10px;
  z-index: 100;
}

/*робив щоб половина меню чіплялась до верху меню, а половина за нижнє (поки що вирішив інакше, але не прибирав, може ще знадобиться)*/
.subgroup-bottom {
  /* bottom: -1px; */
  top: -4px;
}

.subgroup-ul {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 30px;
}

.subgroup-wrap {
  flex-basis: calc((100% - 5px) / 2);
}

@media screen and (min-width: 1024px) {
  .subgroup-ul {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 30px;
  }

  .subgroup-wrap {
    flex-basis: calc((100% - 10px) / 3);
  }

  .subgroup,
  .subgroup-bottom {
    width: 730px;
  }
}

@media screen and (min-width: 1200px) {
  .subgroup-ul {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 30px;
  }

  .subgroup-wrap {
    flex-basis: calc((100% - 15px) / 4);
  }

  .subgroup,
  .subgroup-bottom {
    width: 900px;
  }
}

@media screen and (min-width: 1440px) {
  .subgroup-ul {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    margin-bottom: 30px;
  }

  .subgroup-wrap {
    flex-basis: calc((100% - 20px) / 5);
  }

  .subgroup,
  .subgroup-bottom {
    width: 1150px;
  }
}

.link-block,
.group-block {
  padding: 5px;
  margin: 5px;
  /* border: 1px dotted; */
}

.link-block {
  padding-left: 20px;
}

.group-block {
  /* position: relative; */
  font-weight: 700;
}

.group-block-item,
.group-block-item-more {
  font-weight: 400;
  padding-left: 20px;
}

.group-block-item-more {
  /* position: absolute;
  top: 100%; */
  /* left: 100%; */
  display: none;
  /* width: 1100px;
  max-height: 200px; */
}

.show-more {
  /* display: block; */
  font-weight: 700;
}

.show-more:hover .group-block-item-more {
  display: flex;
  /* background-color: white; */
  /* border: 1px dotted; */
}

.show-more-hide {
  display: block;
  padding-left: 20px;
}

.show-more:hover .show-more-hide {
  display: none;
}

.menu-link,
.show-more-hide {
  text-decoration: none;
  color: black;
}

.group-block-item:hover,
.group-block-item-more:hover,
.link-block:hover {
  /* color: blue;
  text-decoration: underline; */
  background-color: var(--hover-link-color);
}
