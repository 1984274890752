.dictionary-container {
  width: 100%;
  margin-top: 20px;
  font-size: 14px;
}

.dictionary-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.dictionary-title {
  font-size: 18px;
  font-weight: 600;
}

.dictionary-header-btn-block {
  display: flex;
  justify-content: center;
}

.dictionary-header-json-btn {
  position: relative;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  margin-right: 15px;
  padding: 2px 2px 0px 2px;
  border: 2px solid black;
  border-radius: 3px;
  cursor: pointer;
}

.dictionary-header-json-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  border: 1px solid var(--border-color);
}

.dictionary-header-json-menu-item {
  font-size: 12px;
  font-weight: 300;
  padding: 2px 3px;
}

.dictionary-header-json-menu-item:hover {
  background-color: var(--bg-sec);
}

.dictionary-title-add-item {
  cursor: pointer;
}

.dictionary-row,
.dictionary-row-head {
  display: flex;
  align-items: center;
  height: 100%;
  border-bottom: 1px solid var(--border-color);
  border-right: 1px solid var(--border-color);
  cursor: default;
}

.dictionary-row-head {
  font-weight: 600;
  /* margin-bottom: 3px; */
  border-top: 1px solid var(--border-color);
}

.dictionary-row:hover {
  background-color: var(--bg-sec);
}

.dictionary-table-row {
  cursor: default;
}

.dictionary-table-row:hover {
  background-color: var(--bg-sec);
  cursor: pointer;
}

.dictionary-item,
.dictionary-item-icon {
  display: flex;
  padding: 3px 3px;
  height: 100%;
  border-left: 1px solid var(--border-color);
}

.dictionary-item-icon {
  cursor: pointer;
  text-align: center;
}

.dictionary-edit-icon {
  color: black;
}

.dictionary-btn-info {
  position: relative;
}

.dictionary-show-info {
  position: absolute;
  top: 100%;
  right: 100%;
  width: 300px;
  max-height: 400px;
  overflow-y: auto;
  padding: 3px;
  text-align: start;
  background-color: white;
  border: 1px solid var(--border-color);
  z-index: 2;
}

.dictionary-item-row {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.dictionary-item-col1 {
  width: 100px;
}

.dictionary-item-col2 {
  width: 250px;
  margin-left: 5px;
}

.dictionary-button-block {
  display: flex;
  margin-top: 15px;
  /* width: 350px; */
  /* justify-content: space-around; */
}

.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 3;
  cursor: default;
}

.modal-content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  padding-bottom: 5px;
  width: 480px;
  background-color: white;
  color: black;
}

.modal-header-container {
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 10px;
  border-bottom: 1px solid var(--border-color);
}

.modal-header-close-btn {
  cursor: pointer;
}

.modal-header-title {
  font-size: 16px;
}

.modal-header-mode,
.modal-header-mode-active {
  margin: 5px;
  padding: 5px;
  border: 1px solid gray;
  user-select: none;
}

.modal-header-mode-active {
  background-color: rgb(226, 226, 224);
}

.modal-root-group-block {
  margin-left: 5px;
  cursor: pointer;
}

.modal-group-block {
  margin-left: 25px;
  cursor: pointer;
}

.modal-group-block-item {
  margin-left: 45px;
  cursor: pointer;
}

.modal-group-block-item:hover {
  background-color: rgb(179, 179, 179);
}

.modal-group-block:hover {
  background-color: rgb(224, 224, 224);
}

.modal-root-group-block:hover {
  background-color: rgb(241, 241, 241);
}

.modal-value-clear {
  margin-left: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}

.modal-atribute-block {
  margin: 10px 5px;
}
