.button,
.button-deactive {
  width: 100px;
  color: white;
  font-size: 14px;
  text-align: center;
  padding: 5px 10px;
  margin: 5px;
  background-color: var(--bg-prim);
  border: 1px solid var(--border-color);
  border-radius: 5px;
  cursor: pointer;
  user-select: none;
}

.button-deactive {
  background-color: gray;
  cursor: not-allowed;
}
