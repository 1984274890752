.order-container-all {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0px auto;
}

.order-title {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  margin: 10px 0px 0px 10px;
  padding: 10px 0px;
  border-bottom: 2px solid var(--primary-color);
}

.order-container {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.order-details-container {
  width: 100%;
  font-size: 14px;
}

.basket-container {
  /* min-width: 800px; */
  width: 100%;
}

.basket {
  width: 100%;
}

.delivery-np {
  display: flex;
  align-items: baseline;
}

.delivery-item {
  display: flex;
  padding: 0px 0px 20px 0px;
  margin-left: 20px;
  cursor: pointer;
}

.delivery-label {
  padding-left: 5px;
}

.select-np {
  position: relative;
  width: 50%;
  margin: 5px 10px;
  padding: 0px 5px;
  width: 100%;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.select-list {
  position: absolute;
  top: 100%;
  left: 0;
  max-height: 200px;
  width: 100%;
  background-color: var(--secondary-color);
  border: 1px solid;
  overflow: auto;
}

.select-item {
  padding: 2px 10px;
}

.select-item:hover {
  background-color: var(--hover-link-color);
  cursor: pointer;
}

.delivery-area {
  display: flex;
  flex-direction: column;
}

.search-input {
  width: 95%;
  margin: 2px;
  border-radius: 5px;
  color: var(--primary-color);
}

.form-btn {
  display: block;
  text-align: center;
  margin-top: 30px;
}

.form-order {
  display: flex;
}

.order-info-container {
  font-size: 12px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  margin-bottom: 5px;
  padding: 5px 7px;
}

.order-info-header-container {
  position: relative;
  display: flex;
  align-items: center;
}

.order-info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 63px;
  padding: 5px 5px 0px 5px;
  user-select: none;
  cursor: pointer;
}

.order-info-header-status {
  position: absolute;
  top: 5px;
  right: 5px;
  color: blue;
}

.order-info-header-status-new {
  color: violet;
  padding: 1px 3px;
}

.order-info-header-status-wait {
  color: orange;
  padding: 1px 3px;
}

.order-info-header-status-transit {
  color: yellowgreen;
  padding: 1px 3px;
}

.order-info-header-status-ready {
  color: burlywood;
  padding: 1px 3px;
}

.order-info-header-status-done {
  color: green;
  padding: 1px 3px;
}

.order-info-header-status-cancel {
  color: rgb(255, 0, 0);
  padding: 1px 3px;
}

.order-info-header-btn {
  padding: 5px 5px 0px 5px;
}

.order-info-delivery,
.order-info-delivery-staff {
  font-style: italic;
  padding-left: 5px;
  margin: 20px 0px 10px 0px;
}

.order-info-delivery-staff {
  margin: 5px 0px 0px 0px;
}

.order-info-header-images {
  display: flex;
  margin-top: 15px;
  margin-bottom: 10px;
}

.order-info-header-image {
  margin: 0px 5px;
}

.order-info-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: 11px;
  padding: 10px 2px 5px 2px;
}

.order-info-row-img-name {
  display: flex;
  align-items: center;
  width: 75%;
}

.order-info-row-price-quantity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 25%;
}

.order-history-row-name {
  text-decoration: none;
  color: black;
  padding-left: 3px;
}

.order-history-row-name:hover {
  text-decoration: underline;
}

.order-info-header-plus {
  display: flex;
  width: 30px;
  height: 30px;
  border: 1px dotted;
  border-radius: 5px;
  margin-left: 5px;
}

.order-info-header-plus-text {
  display: inline-block;
  width: 100%;
  align-self: center;
  text-align: center;
}

@media screen and (min-width: 481px) {
  .order-info-container,
  .order-info-row {
    font-size: 13px;
  }
}

@media screen and (min-width: 1024px) {
  .order-info-container,
  .order-info-row {
    font-size: 14px;
  }

  .order-history-row-name {
    padding-left: 10px;
  }

  .order-info-row {
    padding: 10px 5px 5px 5px;
  }
}

.form-group {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  margin: 20px;
}

.form-control {
  width: 100%;
  margin-top: 5px;
  border: 1px solid var(--border-color);
  background-color: rgb(232, 240, 254);
}

.change-info-message {
  font-size: 12px;
  color: red;
  text-align: center;
}

.orders-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.orders-list-options-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
  margin-bottom: 5px;
}

@media screen and (min-width: 550px) {
  .orders-list-options-container {
    flex-direction: row;
  }
}

.orders-list-options-date-container {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 5px;
}

@media screen and (min-width: 550px) {
  .orders-list-options-date-container {
    margin-bottom: 0px;
  }
}

.orders-list-options-date-item {
  margin-right: 5px;
}

.orders-list-options-calendar-btn {
  position: relative;
  margin-left: 5px;
  cursor: pointer;
}

.orders-list-options-dateinterval {
  position: absolute;
  left: -30px;
  z-index: 2;
  border: 1px solid var(--border-color);
}

.orders-list-options-status-container {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-left: 10px;
  user-select: none;
}

@media screen and (min-width: 550px) {
  .orders-list-options-status-container {
    font-size: 14px;
  }
}

.orders-list-options-status-select {
  position: relative;
  margin-left: 5px;
  border: 1px solid;
  padding: 5px;
  width: 185px;
}

.status-select-container {
  position: absolute;
  z-index: 2;
  top: 28px;
  left: -1px;
  border: 1px solid;
  background-color: white;
  width: 185px;
  user-select: none;
}

.status-select-td {
  position: relative;
  margin-left: 5px;
}

.status-select-container-td {
  position: absolute;
  z-index: 2;
  top: 15px;
  left: -1px;
  border: 1px solid;
  background-color: white;
  user-select: none;
}

.status-select-item {
  padding: 5px;
}

.status-select-item:hover {
  background-color: var(--hover-link-color);
}

table {
  text-align: left;
}

th,
td {
  padding: 3px 5px;
  user-select: none;
}

th,
td,
tr,
tbody,
table {
  border: 1px solid var(--border-color);
}

.td-open-details:hover {
  text-decoration: underline;
  cursor: pointer;
}
