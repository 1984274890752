.container {
  display: flex;
  flex-direction: column;
  text-indent: 0px;
  margin: 10px auto 0px auto;
}

.section {
  margin-right: 5px;
}

.contacts {
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .contacts {
    flex-direction: row;
    width: 100%;
    padding-bottom: 20px;
  }
}

@media screen and (min-width: 1250px) {
  .container {
    flex-direction: row;
    justify-content: space-around;
    margin: 20px 0px 0px 0px;
  }
}

.info {
  width: 250px;
  margin-bottom: 20px;
}

.image {
  width: 350px;
}

.list {
  font-size: 16px;
  padding: 0px;
  margin: 7px 0px;
}

.icon {
  display: flex;
  align-items: center;
  margin: 0 10px;
}

.caption {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.link {
  display: none;
  font-size: 18px;
  color: var(--primary-color);
  text-decoration: underline;
}

.link:hover {
  color: blue;
}

@media screen and (min-width: 768px) {
  .link {
    display: block;
  }
}

.block {
  display: flex;
  padding: 0px;
  margin: 0px;
}

.icon_list {
  display: flex;
  align-items: center;
  padding: 10px 0px 0px 0px;
}

.icon_list_item {
  padding: 2px;
}
