.Auth-form-container {
  position: relative;
  border: 1px solid var(--border-color);
  padding: 15px;
  padding-bottom: 20px;
  width: 300px;
  margin: auto;
  background-color: var(--secondary-color);
  color: black;
}

@media screen and (min-width: 481px) {
  .Auth-form-container {
    width: 400px;
  }
}

.reg-container {
  /* position: relative; */
  border: 1px solid var(--border-color);
  padding: 15px;
  padding-bottom: 20px;
  width: 300px;
  margin: auto;
  margin-top: 20px;
  background-color: var(--secondary-color);
  color: black;
}

@media screen and (min-width: 768px) {
  .reg-container {
    width: 600px;
  }
}

.modal-x {
  position: absolute;
  top: 15px;
  right: 15px;
  /* text-align: end; */
  cursor: pointer;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  margin: 20px;
}

.form-control {
  width: 100%;
  margin-top: 5px;
  border: 1px solid var(--border-color);
  background-color: rgb(232, 240, 254);
}

.password-eye-block {
  display: flex;
  align-items: baseline;
  width: 100%;
}

.password-btn-eye {
  width: 23px;
  height: 23px;
  margin-left: 7px;
  cursor: pointer;
  user-select: none;
}

.form-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px 20px;
}

.form-btn {
  color: white;
  background-color: var(--primary-color);
  padding: 5px 10px;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.form-footer-link {
  font-size: 12px;
  color: black;
  text-decoration: underline;
  margin-bottom: 3px;
  user-select: none;
  cursor: pointer;
}

.form-footer-link:hover {
  color: var(--bg-prim);
}

.restore-info {
  margin-top: 3px;
  font-size: 12px;
}

.message-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 300px;
  height: 200px;
  padding: 15px;
}

.message-text {
  display: flex;
  text-align: center;
}

.message-footer {
  text-align: center;
}

.restore-reg-message {
  font-size: 12px;
  color: red;
  text-align: center;
}

/* 
.login-form-label {
  margin-bottom: 15px;
} */
/* 
.login-reg-block {
  display: flex;
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
} */

/* .login-reg-item,
.login-reg-item-active {
  padding: 10px 20px;
  margin: auto;
  user-select: none;
  cursor: pointer;
}

.login-reg-item-active {
  font-weight: 700;
} */
