.product-list-item,
.product-list-item-line {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: white;
  /* overflow: hidden; не можна, бо не відображається випадаючий опис*/
  font-size: 14px;
  padding: 10px;
  height: 250px;
  border-radius: 10px;
  border: 1px solid var(--bg-sec);
}

/* @media screen and (max-width: 480px) {
  .product-list-item {
    width: 250px;
  }
} */

@media screen and (min-width: 480px) {
  .product-list-item {
    height: 320px;
    padding: 5px;
  }
}

@media screen and (min-width: 1024px) {
  .product-list-item {
    height: 330px;
  }
}

.favorite-compare-block {
  position: absolute;
  top: 3px;
  right: 3px;
  display: flex;
  flex-direction: column;
}

.favorite-compare-block-line {
  display: flex;
}

.product-list-item-line {
  flex-direction: row;
  margin: 2px 0px;
  height: 130px;
}

.product-list-item:hover,
.product-list-item:focus,
.product-list-item-line:hover,
.product-list-item-line:focus {
  /* box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 1px 4px 6px rgba(0, 0, 0, 0.16); */
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 0px -1px 1px rgba(0, 0, 0, 0.12),
    1px 4px 6px rgba(0, 0, 0, 0.16);
}

@media screen and (min-width: 768px) {
  .product-list-item:hover,
  .product-list-item:focus {
    border-bottom: 0px solid var(--border-color-light);
    border-radius: 10px 10px 0px 0px;
  }
}

@media screen and (min-width: 551px) {
  .product-card-image,
  .product-card-image-line {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 180px;
    max-height: 180px;
    /* text-align: center; */
    padding: 10px;
  }
  .product-card-image-line {
    min-height: 100px;
    max-height: 100px;
  }
  .product-card-image-mob {
    display: none;
  }
}

.product-card-image img,
.product-card-image-line img {
  width: 100%;
  max-height: 180px;
}

.product-card-image-line img {
  max-height: 100px;
}

@media screen and (max-width: 550px) {
  a {
    margin: 0;
    padding: 0;
  }
  .product-card-image-mob {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 110px;
    min-height: 100px;
    /* text-align: center; */
    margin: auto;
    padding: 10px;
  }
  .product-card-image,
  .product-card-image-line {
    display: none;
  }
}

.product-card-image img {
  max-height: 180px;
  height: 100%;
  object-fit: contain;
}

.product-card-image-mob img,
.product-card-image-line img {
  /* .container img { */
  max-height: 100px;
  height: 100%;
  object-fit: contain;
}

.product-card-without-image-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.product-card-without-image-block-line {
  display: flex;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .product-card-without-image-block-line {
    flex-direction: column;
  }
}

.product-card-name,
.product-card-name-line {
  /* text-align: center; */
  color: var(--text-color);
  margin: 0px 5px;
  overflow: hidden;
}

.product-card-name-line {
  padding-left: 5px;
}

@media screen and (max-width: 1023px) {
  .product-card-name,
  .product-card-name-line {
    font-size: 12px;
  }
  .product-card-name {
    margin: 0px;
  }
}

.product-card-name-line {
  font-weight: 600;
  margin: 0px;
}

.product-card-name-description {
  margin-right: auto;
  padding: 0px;
}

.product-card-description,
.product-card-description-icon {
  display: none;
  margin: 5px;
  color: black;
  font-size: 12px;
}

.product-card-description-icon {
  width: 100%;
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  padding: 0px 10px 10px 10px;
  margin: 0px;
  background-color: var(--secondary-color);
  border-radius: 10px;
  border: 1px solid var(--border-color-light);
  border-top: 0px;
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 1px 4px 6px rgba(0, 0, 0, 0.16);
}
/* 
.product-card-description-btn-icon {
  display: inline-block;
  margin-bottom: 5px;
} */

.product-buy-price-favorite-block {
  display: flex;
  align-items: baseline;
}

.product-buy-price-block {
  display: flex;
  align-items: baseline;
  margin-bottom: 5px;
  min-width: 118px;
}

@media screen and (min-width: 768px) {
  .product-card-description,
  .product-card-description-icon {
    display: block;
  }

  .product-buy-price-favorite-block {
    flex-direction: column;
  }
}

@media screen and (min-width: 1200px) {
  .product-card-description {
    font-size: 14px;
  }
  .product-card-name-line {
    font-size: 16px;
  }
}

.product-card-footer {
  display: flex;
  color: var(--text-color);
  font-size: 20px;
  font-weight: 500;
  justify-content: space-between;
  align-items: center;
  /* margin-top: auto;
  margin-bottom: 10px;
  margin-left: 5px;
  margin-right: 5px; */
  /* border: 1px solid; */
}

.product-card-footer-line {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  color: var(--text-color);
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

@media screen and (max-width: 767px) {
  .product-card-footer-line {
    flex-direction: column;
    text-align: start;
    margin-top: 5px;
  }

  .product-card-footer {
    flex-direction: column;
    align-items: start;
    margin-top: 10px;
  }

  .product-card-without-image-block-line {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
}

.no-image {
  font-size: 30px;
}

.not-avalible {
  padding: 6px 10px;
  background-color: var(--hover-link-color);
  color: var(--secondary-color);
  border-radius: 5px;
}

.product-price,
.product-price-retail {
  margin-left: 0px;
  margin-right: 5px;
  padding: 1px;
  font-size: 10px;
  color: var(--bg-prim);
  /* border: 1px solid var(--bg-prim); */
}

.product-price-retail {
  font-size: 16px;
  border: 0px;
}
/* 
.product-price-retail-staff {
  color: red;
} */

.product-prices-staff-icon,
.product-prices-staff-line {
  display: flex;
  margin-bottom: 3px;
}

@media screen and (min-width: 767px) {
  .product-prices-staff-line {
    flex-direction: column;
  }
}

.btn-buy-off {
  background-color: var(--border-color);
  fill: white;
  padding: 5px 10px;
  cursor: not-allowed;
  border-radius: 5px;
  margin-right: 5px;
}

.btn-buy-svg-off {
  display: flex;
  align-items: center;
}

.date-modified {
  margin-left: 7px;
  font-size: 10px;
}

.price-na {
  margin-left: 0px;
  margin-right: 5px;
  padding: 1px;
  font-size: 17px;
  color: var(--border-color);
  cursor: not-allowed;
}

.price-block-line {
  display: flex;
  flex-direction: column;
}

.edit-icon {
  color: var(--primary-color);
  cursor: pointer;
}

.modal-edit-prices-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 3;
  cursor: default;
}

.modal-edit-prices-content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  width: 50%;
  background-color: white;
  color: black;
}

.modal-add-prices-content {
  margin-left: auto;
  margin-right: auto;
  margin-top: 10%;
  width: 350px;
  background-color: white;
  color: black;
}

.modal-add-prices-form-container {
  padding: 5px;
}

.modal-header-container {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-bottom: 1px solid var(--border-color);
}

.modal-header-close-btn {
  cursor: pointer;
}

.modal-header-title {
  font-size: 16px;
}

.modal-edit-add-price {
  text-align: right;
  padding: 4px 10px;
  user-select: none;
}

.modal-edit-prices-table {
  padding: 0px 10px 20px 10px;
}

.modal-edit-prices-row,
.modal-edit-prices-row-header {
  display: flex;
  justify-content: center;
  font-size: 10px;
}

.modal-edit-prices-row:hover {
  background-color: var(--hover-link-color);
}

.modal-edit-prices-row-header {
  font-size: 12px;
}

.modal-edit-prices-item,
.modal-edit-prices-item-block,
.modal-edit-prices-item-need {
  text-align: center;
  border: 1px solid var(--border-color);
}

.modal-edit-prices-item-need {
  position: relative;
  background-color: yellow;
}

.modal-edit-prices-item-block:hover,
.modal-edit-add-price:hover {
  color: blue;
  cursor: pointer;
  text-decoration: underline;
}

.modal-edit-price-new-vendor {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border: 1px solid;
}

.modal-edit-product-category {
  text-align: left;
  padding: 4px 10px;
  user-select: none;
  cursor: pointer;
}
