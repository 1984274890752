.container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7px;
  padding: 0;
  /* text-align: center; */
  /* margin: auto 0px; */
  color: var(--secondary-color);
  background-color: red;
  /* padding: 5px; */
  border-radius: 50%;
  width: 15px;
  height: 15px;
}
