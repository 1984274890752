.container {
  display: flex;
  align-items: center;
}

.input-container {
  position: relative;
  margin: 0px 10px;
}

.input,
.select-element,
.select-element-x {
  border: 1px solid grey;
  padding: 2px 5px;
  height: 23px;
  width: 100%;
  cursor: pointer;
}

.select-element {
  white-space: nowrap; /* відміна автоматичного переносу */
  overflow: hidden; /* обрізання тексту, який виходить за межі блоку */
  text-overflow: ellipsis; /* відображення місця обрізаного тексту */
}

.select-element-x {
  border: 1px solid grey;
  background-color: rgb(207, 205, 205);
  padding: 2px 5px;
  height: 23px;
  width: 20px;
}

.select-btn {
  border-top: 1px solid grey;
  border-bottom: 1px solid grey;
  border-right: 1px solid grey;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 2px 5px;
  width: 23px;
  height: 23px;
  cursor: pointer;
  background-color: aliceblue;
}

.select-list {
  position: absolute;
  z-index: 2;
  top: 10;
  left: 0;
  border: 1px solid grey;
  background-color: white;
  width: 100%;
  max-height: 300px;
  overflow: auto;
}

.select-item,
.select-item-active {
  padding: 2px 5px;
  cursor: pointer;
}

.select-item-active {
  background-color: var(--hover-link-color);
}

.backdrop-modal {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
}

.dictonary-container {
  margin: 5px;
  height: 95%;
  border: 1px solid var(--hover-link-color);
  background-color: white;
}
