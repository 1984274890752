.container-all {
  width: 100%;
}

.page-title {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  margin: 15px 0px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--primary-color);
}

.page-title-link {
  color: black;
}

.page-title-link:hover {
  text-decoration: underline;
}

.page-title-mode {
  font-size: 14px;
  color: var(--primary-color);
  text-decoration: underline;
}

.page-title-mode:hover {
  cursor: pointer;
  color: blue;
}

.product-row {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.list-block,
.list-block-row,
.list-block-two {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.list-block {
  margin-bottom: 15px;
}
/* 
.list-block-row:hover {
  background-color: var(--hover-link-color);
} */

.list-item,
.list-item-row,
.list-item-row-header {
  width: 300px;
  margin-right: 5px;
}

.list-item-row,
.list-item-row-header,
.list-item-row-header-name {
  font-size: 14px;
  color: black;
  padding: 5px;
}

.list-item-row-header {
  display: flex;
  flex-direction: column;
}

.list-item-row-header-name:hover {
  text-decoration: underline;
}

.option-block,
.option-block-header {
  /* margin: 15px 0px; */
  border-right: 1px solid var(--border-color);
  border-left: 1px solid var(--border-color);
  border-top: 1px solid var(--border-color);
}
/* 
.option-block:first-child {
  border-top: 1px solid var(--border-color);
} */

.option-block:hover {
  background-color: var(--hover-link-color);
}

.option-title {
  font-size: 16px;
  font-weight: 600;
  /* padding-bottom: 5px; */
  padding: 5px;
  /* border-bottom: 1px solid var(--border-color); */
}
