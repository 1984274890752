.container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 5px;
}

.textarea {
  border: 1px solid grey;
  /* border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
  padding: 2px 5px;
  margin-left: 5px;
  /* width: 250px; */
  cursor: pointer;
}

.textarea-label {
  width: 100px;
  margin-left: 5px;
  margin-bottom: 2px;
}
