/* Малий смартфон (XS): Зазвичай 320px - 480px.
Середній смартфон (S, M): Зазвичай 481px - 767px.
Великий смартфон (L, XL): Зазвичай 768px - 1023px.
1024px - 1200px та більше 1200px
максимальна ширина 1500px */

:root {
  --primary-color: rgb(11, 71, 164);
  --secondary-color: white;
  --bg-prim: rgb(47, 111, 213);
  --bg-sec: rgb(241, 241, 241);
  --bg-gray: rgb(251, 251, 251);
  --text-color: black;
  --hover-link-color: rgb(222, 234, 245);
  --border-color: rgb(218, 218, 218);
}

body {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* font-family: "Roboto", Arial, Geneva, Verdana, sans-serif; */
  background-color: var(--secondary-color);
  color: var(--text-color);
  margin: 0;
}

.display-flex {
  display: flex;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary-color);
  color: var(--secondary-color);
  height: 30px;
  width: 100%;
  margin-top: 10px;
}

h1,
h2 {
  text-align: center;
  align-self: center;
}

ul {
  list-style: none;
  padding: 0px;
}

input {
  font-size: 14px;
  border-color: var(--primary-color);
  padding: 3px 10px;
}

input:focus {
  outline: 0;
  outline-offset: 0;
  border-color: var(--primary-color);
}

.page-title {
  width: 100%;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid var(--primary-color);
}

.modal-open {
  overflow: hidden;
}

.container-all {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1530px;
  min-height: 900px;
  padding: 0px 15px;
  font-size: 18px;
}

.main {
  display: flex;
  width: 100%;
  margin: 0 auto;
}

.content {
  /* width: auto; */
  width: 85%;
  padding: 10px 0px;
}

.portal-content {
  width: 85%;
  margin-left: 3px;
  margin-right: 3px;
}

.home-sidebar {
  display: none;
}

@media screen and (min-width: 900px) {
  .home-sidebar {
    display: block;
  }
}

.sidebar,
.filters {
  width: 250px;
  margin: 0px 20px 0px 10px;
}

.content h2 {
  text-align: center;
}

.category-path {
  font-size: 18px;
}

.pages-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pages-container {
  font-size: 14px;
  margin-top: 10px;
  padding: 10px;
  text-indent: 25px;
  text-align: justify;
}

.btn {
  margin: 3px;
  padding: 5px 20px;
  border: 1px solid;
  border-color: var(--primary-color);
  /* border-radius: 5px; */
  background-color: var(--primary-color);
  font-size: 15px;
  color: var(--secondary-color);
  text-decoration: none;
}

.btn:hover {
  color: yellow;
  cursor: pointer;
}

.hide {
  display: none;
}

.show {
  display: inline;
}

.filter-grp {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filter-onoff {
  padding: 10px 15px;
  text-align: center;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  background-color: var(--secondary-color);
}

.pagination-block {
  padding: 15px 0px;
}

.pagination-button {
  padding: 5px 10px;
  margin: 0px 1px;
  border: 1px solid white;
  /* border-radius: 5px; */
  color: var(--primary-color);
}

.pagination-button:hover {
  border: 1px solid var(--secondary-color);
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

.pagination-button-active {
  padding: 0px 15px;
  font-weight: 700;
}

.pagination-prev-next {
  user-select: none;
}

.profile-all-container {
  width: 100%;
}
.profile-container {
  display: flex;
}

.spinner {
  margin: 20% auto auto auto;
}

/* .svg-icon {
  margin-right: 2px;
} */

.svg-icon svg {
  fill: rgb(183, 183, 183);
}

.backdrop-modal,
.backdrop-modal-transparent {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(102, 102, 102, 0.8); */
  background-color: rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  z-index: 3;
}

.backdrop-modal-transparent {
  background-color: rgba(0, 0, 0, 0);
  z-index: 4;
}

/* Властивості інпуту дати, перекриваємо стандартіні налаштування компонента */
.react-datepicker-wrapper input {
  width: 100px;
  border-color: var(--border-color);
}
