.product-list-container,
.product-list {
  width: 100%;
}
/* 
.product-list {
  margin-left: 0px;
}

@media screen and (min-width: 1024px) {
  .product-list {
    margin-left: 15px;
  }
    
} */

.product-list-filters-block {
  display: flex;
}

.filters-block {
  display: none;
}

@media screen and (min-width: 1024px) {
  .filters-block {
    display: inline-block;
    min-width: 265px;
  }
}

.path-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
}

.path-link,
.path-link-name {
  text-decoration: none;
  font-size: 16px;
  color: black;
  padding: 0px 5px;
  user-select: none;
}

@media screen and (max-width: 767px) {
  .path-link {
    font-size: 14px;
    text-decoration: underline;
  }
  .path-link-name {
    display: none;
  }
}

.path-item:hover {
  text-decoration: underline;
  /* border-radius: 5px;
  background-color: var(--hover-link-color); */
}

.header-product-list {
  display: none;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--primary-color);
}

@media screen and (min-width: 768px) {
  .header-product-list {
    display: flex;
  }
}

.header-product-list-title-count-block {
  display: flex;
  align-items: baseline;
}

@media screen and (max-width: 480px) {
  .header-product-list-title-count-block {
    display: none;
  }
  .header-product-list {
    justify-content: center;
  }
}

.header-product-list-title {
  font-size: 20px;
  font-weight: 500;
}

.header-product-list-count {
  margin-left: 20px;
  font-size: 12px;
  color: grey;
}

.header-product-list-options-block {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.header-product-list-select-filters-block {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 10px 0px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--primary-color);
}

.header-product-list-select-filters-item {
  display: flex;
  color: black;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  background-color: rgb(241, 241, 241);
  padding: 3px 5px;
  margin: 3px;
}

.header-product-list-select-filters-item-link {
  color: black;
}

.header-product-list-select-filters-item-link:hover {
  text-decoration: underline;
}

.header-product-list-select-filters-item-x {
  margin-left: 5px;
  fill: red;
  cursor: pointer;
}

.sort-text,
.sort-item,
.sort-item-selected,
.select-label {
  display: none;
}

@media screen and (min-width: 1200px) {
  .sort-text,
  .select-label {
    display: inline;
  }
}

@media screen and (min-width: 1024px) {
  .sort-item,
  .sort-item-selected {
    display: inline;
  }
}

.select,
.select-label {
  padding-right: 5px;
  border-radius: 0px;
}

.select-vendor {
  margin-left: 10px;
}

@media screen and (max-width: 480px) {
  .select {
    display: none;
  }
}

.sort-text,
.sort-item,
.sort-menu-item,
.sort-menu-item-selected,
.sort-item-selected {
  padding: 0px 5px;
}

.sort-item,
.sort-menu-item,
.sort-menu-item-selected,
.sort-item-selected {
  padding: 3px 10px;
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
}

.sort-menu-item,
.sort-menu-item-selected {
  padding: 5px 5px;
  text-decoration: none;
}

.sort-menu-item-selected,
.sort-item-selected {
  font-weight: 600;
}

.sort-item:hover,
.sort-item-selected:hover {
  background-color: var(--hover-link-color);
}

.select-btn-container {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.select-btn-filter,
.select-btn-sort {
  color: white;
  background-color: var(--bg-prim);
  padding: 5px 10px;
  margin-right: 10px;
}

@media screen and (min-width: 1024px) {
  .select-btn-filter,
  .select-btn-sort {
    display: none;
  }
}

.select-btn-sort-block {
  position: relative;
}

.sort-menu {
  position: absolute;
  top: 25px;
  left: 0;
  z-index: 2;
  width: 150px;
  background-color: white;
  border: 1px solid var(--border-color);
}

.search-container {
  margin-left: 10px;
}

.search-category-header {
  color: var(--primary-color);
  margin: 5px 5px;
}

.search-category-list {
  display: inline;
}

.search-category-item,
.search-category-item-active {
  display: inline;
  color: var(--primary-color);
  font-size: 14px;
  padding: 0 5px;
  border-radius: 5px;
}

.search-category-item-active {
  font-weight: 700;
}

.search-category-item:hover,
.search-category-item-active:hover {
  background-color: var(--hover-link-color);
}

.icon-line-btn,
.icon-line-btn-active {
  margin-left: 10px;
  padding: 2px 10px 0px 10px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  /* border-radius: 5px; */
}

.icon-line-btn:hover {
  background-color: var(--hover-link-color);
}

.icon-line-btn-active {
  color: var(--secondary-color);
  background-color: var(--primary-color);
}

.icon-line-btn-active:hover {
  color: yellow;
}

.no-products-text {
  color: var(--primary-color);
  margin: 15px;
}

.modal-filters-container {
  padding: 0px 15px;
  width: 300px;
  min-height: 100%;
  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
}

.modal-filters-header {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 15px;
}

.modal-filters-header-btn {
  padding: 5px;
  /* margin: 0px 0px 0px 5px; */
  font-size: 14px;
  color: white;
  background-color: var(--bg-prim);
}

.filter-sort-footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-top: 1px solid var(--primary-color);
  background-color: white;
}

@media screen and (min-width: 768px) {
  .filter-sort-footer {
    display: none;
  }
}

.footer-btn {
  color: white;
  font-size: 14px;
  background-color: var(--bg-prim);
  padding: 5px 15px;
  margin-left: 10px;
}

.sort-menu-footer {
  position: fixed;
  bottom: 30px;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: white;
  border-top: 1px solid var(--primary-color);
  border-bottom: 1px solid var(--primary-color);
  font-size: 12px;
}

.product-list-pagination-container {
  display: flex;
  margin-left: 10px;
}

.product-list-pagination-item,
.product-list-pagination-item-active {
  font-size: 12px;
  text-align: center;
  margin: 0px 3px;
  padding: 5px;
  min-width: 25px;
  border: 1px solid var(--border-color);
  cursor: pointer;
}

.product-list-pagination-item-active {
  border: 1px solid var(--bg-prim);
}

/* Pagination OLD */

.product-list-pag {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  /* один відступ 15  */
  gap: 15px;
  margin: 10px 0px;
}

.product-list-wrap {
  /* margin: auto; */
  /* 45 = (4 * 15) - 15 останнього */
  flex-basis: calc((100% - 15px) / 2);
  min-width: calc((100% - 15px) / 2);
  max-width: calc((100% - 15px) / 2);
}

@media screen and (min-width: 481px) {
  .product-list-wrap {
    flex-basis: calc((100% - 15px) / 2);
    min-width: calc((100% - 15px) / 2);
    max-width: calc((100% - 15px) / 2);
  }
}

@media screen and (min-width: 1024px) {
  .product-list-wrap {
    flex-basis: calc((100% - 30px) / 3);
    min-width: calc((100% - 30px) / 3);
    max-width: calc((100% - 30px) / 3);
  }
}

@media screen and (min-width: 1400px) {
  .product-list-wrap {
    flex-basis: calc((100% - 45px) / 4);
    min-width: calc((100% - 45px) / 4);
    max-width: calc((100% - 45px) / 4);
  }
}

.paginate {
  display: flex;
  margin-top: 30px;
  font-size: 10px;
}

@media screen and (min-width: 481px) {
  .paginate {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .paginate {
    font-size: 14px;
  }
}

.pagination-footer-container {
  margin-top: 20px;
}

.price-eye {
  margin-top: 1px;
  margin-left: 10px;
  cursor: pointer;
}

.pricelist-block {
  width: 100%;
  padding: 10px 0px;
  border-top: 1px solid rgb(192, 191, 191);
  border-bottom: 1px solid rgb(192, 191, 191);
}