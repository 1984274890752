.delivery-np {
  display: flex;
  align-items: baseline;
  margin-left: 20px;
}

.select-np {
  position: relative;
  z-index: 0;
  width: 50%;
  margin: 5px 10px;
  padding: 0px 5px;
  width: 100%;
  border: 1px solid var(--border-color);
  /* border-radius: 5px; */
  user-select: none;
}

.select-list {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1;
  max-height: 200px;
  width: 100%;
  background-color: var(--secondary-color);
  border: 1px solid var(--border-color);
  overflow: auto;
}

.select-item {
  padding: 2px 10px;
}

.select-item:hover {
  background-color: var(--hover-link-color);
  cursor: pointer;
}

.search-input {
  width: 95%;
  margin: 2px;
  border-color: var(--border-color);
  /* border-radius: 5px; */
}

.adress-np-input {
  width: 100%;
  font-size: 14px;
  padding: 0px 5px;
  margin: 5px 10px;
  border: 1px solid var(--border-color);
  /* border-radius: 5px; */
}
