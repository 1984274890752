.search {
  display: flex;
  width: 100%;
  align-items: center;
  text-align: center;
  color: var(--secondary-color);
  padding-right: 20px;
}

.search-input {
  margin: 0px 0px;
  width: 100%;
  font-size: 16px;
  border: 1px solid var(--primary-color);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.search-btn {
  padding-top: 2px;
  border: 2px solid var(--primary-color);
  color: var(--secondary-color);
  background-color: var(--primary-color);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  cursor: pointer;
}
