.container-main {
  position: relative;
  width: 100%;
}

.header-main-title-blok {
  margin: 0px 10px;
  padding: 20px 0px;
  border-bottom: 2px solid var(--primary-color);
}

.header-main-title {
  font-size: 24px;
  font-weight: 500;
}

.container {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 10px;
}

.group {
  flex-basis: calc((100% - 5px) / 2);
  margin-top: 20px;
  text-decoration: none;
}

@media screen and (min-width: 481px) {
  .group {
    flex-basis: calc((100% - 10px) / 3);
  }
}

@media screen and (min-width: 768px) {
  .group {
    flex-basis: calc((100% - 15px) / 4);
  }
}

@media screen and (min-width: 1024px) {
  .group {
    flex-basis: calc((100% - 20px) / 5);
  }
}

@media screen and (min-width: 1200px) {
  .group {
    flex-basis: calc((100% - 25px) / 6);
  }
}

a {
  text-decoration: none;
}

.group-label {
  margin: 30px 0px 0px 10px;
  padding-bottom: 15px;
  border-bottom: 1px solid var(--border-color);
  font-size: 18px;
  font-weight: 700;
  color: black;
}

.group-label:hover {
  text-decoration: underline;
}

.item {
  /* width: 135px; */
  width: 100%;
}

.item:hover {
  text-decoration: underline;
}

.item-img {
  border: 1px solid var(--border-color);
  padding: 5px;
}

.item-name {
  min-height: 50px;
  border: 1px solid var(--border-color);
  padding: 10px;
  text-align: center;
  font-size: 12px;
  color: black;
  font-weight: 500;
}

@media screen and (min-width: 1024px) {
  .item-name {
    font-size: 14px;
  }
  .group-label {
    font-size: 20px;
  }
}

.add-image {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 12px;
  text-decoration: underline;
  margin-top: 10px;
  margin-right: 10px;
}

.add-image:hover {
  color: blue;
  cursor: pointer;
}

.add-image-card {
  display: flex;
  align-content: center;
  justify-content: space-between;
}
