.product-container {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.product-card-without-path-block {
  display: flex;
  /* width: 100%; */
}

.product-head {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 10px 0px;
}

.product-head-img-info-block {
  display: flex;
  flex-direction: column-reverse;
}

@media screen and (min-width: 768px) {
  .product-head-img-info-block {
    flex-direction: row;
  }
}

.img-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.product-head-info {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0px auto;
  padding: 15px;
  background-color: var(--bg-gray);
}

@media screen and (min-width: 768px) {
  .img-block {
    width: 40%;
  }
  .product-head-info {
    width: 60%;
    padding: 30px;
  }
  .product-head-info-title {
    font-size: 20px;
    font-weight: 700;
  }
}

.info-banner-block {
  display: none;
}

@media screen and (min-width: 1200px) {
  .info-banner-block {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* width: 20%; */
    min-width: 300px;
    margin-left: 10px;
    font-size: 14px;
  }
}

.product-head-info-title {
  font-size: 18px;
  font-weight: 500;
}

@media screen and (min-width: 768px) {
  .product-head-info-title {
    font-size: 20px;
    font-weight: 700;
  }
}
.product-head-info-option {
  font-size: 14px;
  font-weight: 700;
  color: grey;
  margin-top: 5px;
}

.product-options {
  width: 100%;
  font-size: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  margin-top: 10px;
}

@media screen and (min-width: 481px) {
  .product-options {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .product-options {
    font-size: 14px;
  }
}

.product-options-title {
  font-weight: 600;
  margin-top: 15px;
}

.product-options-string {
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.product-options-string:last-child {
  border-bottom: 0px solid rgba(0, 0, 0, 0.1);
}

.product-options-string:hover {
  background-color: var(--hover-link-color);
}

.product-options-name {
  min-width: 25%;
  max-width: 25%;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 3px 5px;
}

.product-options-value {
  /* width: 20%; */
  padding: 3px 5px;
}

.img {
  display: flex;
  justify-content: center;
  align-items: center;
  /* width: 250px; */
  height: 250px;
  margin: 15px;
  cursor: pointer;
}

@media screen and (min-width: 768px) {
  .img {
    /* width: 300px; */
    height: 300px;
    margin: 5px;
  }
}

.img img {
  max-height: 300px;
  /* height: 100%; */
  object-fit: contain;
}

.img-small-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  padding: 10px;
}

.img-small {
  width: 60px;
  margin: 4px;
  padding: 3px;
  cursor: pointer;
  border: 1px solid var(--secondary-color);
}

.img-small:hover {
  border: 1px solid var(--primary-color);
  border-radius: 5px;
}

.product-price-retail {
  color: var(--bg-prim);
  font-size: 20px;
  font-weight: 600;
  margin: 20px 5px;
}

.product-our-prices {
  display: flex;
  padding: 10px 0px;
}

.product-price {
  display: flex;
  align-items: baseline;
  color: var(--bg-prim);
  font-size: 14px;
  margin-right: 15px;
}

.not-avalible {
  padding: 5px 10px;
  background-color: var(--hover-link-color);
  color: var(--secondary-color);
  border-radius: 5px;
}

.modal-block {
  display: flex;
  width: 80%;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  margin: 0 auto;
}

.modal-big-image-block {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0px auto;
  padding: 10px;
}

.modal-big-image {
  width: 70%;
  text-align: center;
  padding: 30px;
  background-color: white;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 5px;
  user-select: none;
}

.modal-big-inside-block {
  text-align: center;
  user-select: none;
}

.modal-small-image-block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 10px;
}

.modal-small-image,
.modal-small-image-active {
  /* width: 5%; */
  text-align: center;
  margin: 5px;
  padding: 10px;
  background-color: white;
  border: 1px solid rgb(173, 173, 173);
  border-radius: 5px;
  user-select: none;
}

.modal-small-image-active {
  border: 1px solid red;
}

.modal-arrow-block {
  display: none;
}

@media screen and (min-width: 481px) {
  .modal-arrow-block {
    display: block;
    padding: 15px;
    fill: white;
    /* background-color: rgb(240, 240, 240);
    border: 1px solid rgb(173, 173, 173);
    border-radius: 5px; */
    user-select: none;
  }
}

.modal-close-block {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  user-select: none;
}

.modal-close-btn {
  margin-top: 5px;
  padding: 5px 5px 3px 5px;
  fill: white;
  /* background-color: rgb(240, 240, 240);
  border: 1px solid rgb(173, 173, 173);
  border-radius: 5px; */
}

.product-buy-btn-price-block {
  display: flex;
  align-items: baseline;
}

.product-buy-btn {
  font-weight: 700;
  display: inline-block;
  margin: 10px 10px 10px 0px;
}

.product-buy-btn-red {
  display: inline-block;
  background-color: red;
}

.product-disr-warranty-block {
  padding-top: 20px;
  border-top: 1px dotted;
  font-size: 14px;
}

.info-block {
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
  margin-left: 10px;
  border-bottom: 1px dotted gray;
}

.info-svg {
  fill: var(--primary-color);
  margin-right: 10px;
}

.info-block-title {
  display: flex;
  align-items: center;
  color: var(--primary-color);
}

.info-block-item {
  margin-top: 5px;
  margin-left: 2px;
}

.info-block-item-link {
  text-decoration: underline;
  color: var(--primary-color);
}
