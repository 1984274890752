.container {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .select {
    border: 1px solid grey;
    /* border-top-left-radius: 5px;
    border-bottom-left-radius: 5px; */
    padding: 2px 5px;
    margin-left: 5px;
    width: 250px;
    height: 30px;
    cursor: pointer;
  }
  
  .select-label {
    width: 100px;
  }