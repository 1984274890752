header {
  height: 120px;
  border-top: 3px solid var(--primary-color);
}

.header-nav-container {
  background-color: var(--secondary-color);
  height: 65px;
}

@media screen and (min-width: 768px) {
  header {
    height: 150px;
  }
  .header-nav-container {
    height: 100px;
  }
}

.header-nav {
  display: flex;
  justify-content: space-between;
  max-width: 1530px;
  height: 100%;
  padding: 0px 15px;
  margin: 0px auto;
}

.nav-mobile-catalog-btn {
  display: flex;
  align-items: center;
  justify-items: center;
  fill: var(--primary-color);
  margin-left: 30px;
}

@media screen and (max-width: 480px) {
  .nav-mobile-catalog-btn {
    margin-left: 15px;
  }
}

@media screen and (min-width: 768px) {
  .nav-mobile-catalog-btn {
    display: none;
  }
}
/* 
.logo-block {
  display: none;
}

@media screen and (min-width: 1024px) {
  .logo-block {
    display: flex;
    align-items: center;
    margin-left: 13px;
  }
} */

.logo-block-small {
  display: flex;
  align-items: center;
  margin-left: 13px;
}

.nav-menu {
  display: none;
}

@media screen and (min-width: 768px) {
  .nav-menu {
    list-style: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
  }
}

.nav-menu-item {
  display: flex;
  font-size: 13px;
  color: black;
  text-align: center;
  text-decoration: underline;
  padding: 10px;
  user-select: none;
}

.contact-menu {
  display: none;
}

@media screen and (min-width: 1200px) {
  .contact-menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 13px;
    font-weight: 500;
  }
}

.contact-menu-item {
  margin: 2px 5px;
}

.contact-menu-phones {
  display: flex;
  margin: 0px;
  padding: 0px;
}

.contact-menu-phones-item {
  margin-right: 10px;
  margin-left: 5px;
  color: black;
}

.contact-menu-phones-item a {
  color: black;
  cursor: pointer;
}

.contact-menu-line-block {
  display: flex;
  align-items: center;
  margin-left: 5px;
}

.buttons {
  display: flex;
  align-items: center;
}

.header-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--primary-color);
  padding: 2px 10px;
  margin: 0px 2px;
  border: 1px solid white;
  border-radius: 5px;
}

.header-icon-admin {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* color: var(--primary-color); */
  padding: 2px 10px;
  margin: 0px 2px;
  cursor: pointer;
  /* border: 1px solid white;
  border-radius: 5px; */
}

.header-icon:hover {
  border: 1px solid var(--bg-prim);
  cursor: pointer;
}

.header-icon {
  position: relative;
}

.header-icon-login-logout {
  display: none;
}

@media screen and (min-width: 481px) {
  .header-icon-login-logout {
    display: block;
  }
}

.header-basket_circle {
  position: absolute;
  top: 0px;
  right: 5px;
}

.header-icon svg {
  fill: var(--primary-color);
}

.header-icon-admin svg {
  fill: white;
}

.header-icon-label {
  font-size: 10px;
  text-align: center;
  padding: 0;
  margin: 3px 0px 0px 0px;
  user-select: none;
}

.header-search-container {
  background-color: var(--bg-prim);
  height: 50px;
}

.header-search {
  display: flex;
  justify-content: space-between;
  max-width: 1530px;
  height: 50px;
  padding: 0px 15px;
  margin: 0px auto;
  font-size: 18px;
}

.header-search-catalog {
  display: none;
}

@media screen and (min-width: 768px) {
  .header-search-catalog {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-width: 250px;
    margin: 0px 0px 0px 0px;
    background-color: var(--primary-color);
    cursor: pointer;
  }
}

.header-search-catalog-text {
  width: 100%;
  text-align: center;
  font-size: 14px;
  /* font-weight: 600; */
  color: white;
}

.header-search-catalog-text-name {
  font-size: 14px;
  font-weight: 500;
}

.header-search-catalog-text-email {
  color: white;
  font-size: 10px;
  margin-top: 3px;
}

.svg-icon-catalog-btn {
  margin: 20px;
  fill: white;
}

.catalog-menu {
  position: absolute;
  z-index: 99;
  top: 47px;
  left: 0px;
}

.header-search-block {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-left: 20px;
}

.profile-btn {
  display: none;
}

@media screen and (min-width: 768px) {
  .profile-btn {
    position: relative;
    display: flex;
    align-items: center;
    min-width: 210px;
    height: 100%;
    border-left: 1px solid var(--primary-color);
    border-right: 1px solid var(--primary-color);
    padding: 20px 15px;
    font-size: 16px;
    font-weight: 600;
    color: white;
    cursor: pointer;
    user-select: none;
  }
}

.profile-menu {
  position: absolute;
  top: 34px;
  left: 0;
  z-index: 2;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0px 10px 5px 10px;
  color: var(--primary-color);
  background-color: var(--bg-sec);
  /* background-color: white; */
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.12), 1px 4px 6px rgba(0, 0, 0, 0.16);
  border: 1px solid var(--bg-sec);
  border-end-end-radius: 5px;
  border-end-start-radius: 5px;
  user-select: none;
}

.profile-menu-item {
  display: flex;
  font-size: 14px;
  color: black;
  text-decoration: underline;
  padding: 10px;
  user-select: none;
}

.profile-menu-item:hover {
  color: var(--primary-color);
}

.svg-icon-profile-btn {
  margin-left: 2px;
  fill: white;
}

.modal-basket-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 98%;
  height: 100%;
  transform: translate(1%, 25%);
  z-index: 99;
}

@media screen and (min-width: 800px) {
  .modal-basket-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 75%;
    transform: translate(16%, 29%);
    z-index: 99;
  }
}
@media screen and (min-width: 1200px) {
  .modal-basket-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 75%;
    height: 75%;
    transform: translate(16%, 29%);
    z-index: 99;
  }
}

.modal-message-container {
  position: absolute;
  top: 50%; /* Позиціонуємо вікно на 50% від верхнього краю */
  left: 50%; /* Позиціонуємо вікно на 50% від лівого краю */
  width: 300px; /* Задаємо ширину вікна */
  height: 200px; /* Задаємо висоту вікна */
  transform: translate(
    -50%,
    -50%
  ); /* Використовуємо трансформацію, щоб точно центрувати вікно */
  z-index: 99;
}
