.main-container {
  padding: 0px 15px;
  width: 300px;
  min-height: 100%;

  background-color: white;
  position: absolute;
  top: 0;
  left: 0;
}

.header {
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-left: 15px;
}

.user-name {
  font-size: 12px;
}

.user-email {
  font-size: 10px;
  color: gray;
}

.catalog-title {
  margin-top: 10px;
  padding: 10px 15px;
  color: white;
  background-color: var(--bg-prim);
}

.catalog-menu {
  position: relative;
  margin: 3px;
  padding-bottom: 15px;
  font-size: 14px;
  border-bottom: 1px solid var(--border-color);
}

.catalog-group {
  padding: 5px 5px 5px 10px;
  background-color: var(--secondary-color);
}

.catalog-group-name {
  text-decoration: none;
  color: black;
}

.nav-menu,
.profile-menu {
  list-style: none;
  margin: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}

.profile-menu {
  border-bottom: 0;
}

.nav-menu-item {
  display: flex;
  font-size: 13px;
  color: black;
  text-align: center;
  text-decoration: underline;
  padding: 10px;
  user-select: none;
}
