.sidebar-block {
    /* min-height: 500px; */
    /* border: 1px dotted var(--primary-color); */
    /* padding: 5px; */
    width: 250px;
  }
  
  .sidebar-header {
    padding: 14px 5px;
    border-bottom: 1px solid var(--border-color);
  }
  
  .sidebar-header-name {
    font-size: 14px;
    font-weight: 500;
  }
  
  .sidebar-header-email {
    color: gray;
    font-size: 10px;
    margin-top: 3px;
  }
  
  .sidebar-item,
  .sidebar-item-active {
    font-size: 14px;
    color: black;
    /* background-color: var(--secondary-color); */
    /* border: 1px dotted var(--primary-color); */
    /* border-radius: 5px; */
    padding: 5px;
    margin-bottom: 5px;
    cursor: pointer;
    user-select: none;
  }
  
  .sidebar-item:hover {
    text-decoration: underline;
  }
  
  .sidebar-item-active {
    color: var(--primary-color);
    text-decoration: underline;
    /* background-color: var(--primary-color); */
  }
  
  .order-history-block {
    width: 100%;
    margin-top: 20px;
  }
  
  .side-menu-block {
    display: none;
  }
  
  @media screen and (min-width: 768px) {
    .side-menu-block {
      display: block;
      margin-right: 20px;
    }
  
    .order-history-block {
      width: 80%;
      margin-top: 20px;
    }
  }
  